<!--
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	export let src: string;
	export let isTransparent = false;

	function customEncodeURI(str: string) {
		return str.split('/').map(encodeURIComponent).join('/');
	}

	$: encodedSrc = customEncodeURI(src);
</script>

{#if !isTransparent}
	<picture>
		<source srcset="{encodedSrc}.avif" type="image/avif" />
		<source srcset="{encodedSrc}.webp" type="image/webp" />
		<img src="{encodedSrc}.jpg" {...$$restProps} />
	</picture>
{:else}
	<picture>
		<source srcset="{encodedSrc}.avif" type="image/avif" />
		<source srcset="{encodedSrc}.webp" type="image/webp" />
		<img src="{encodedSrc}.png" {...$$restProps} />
	</picture>
{/if}

<style lang="scss">
	picture {
		display: contents;
	}
</style>
